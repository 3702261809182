import React from 'react'
import { getServerSideProps } from 'localization'
import { PageWrapper } from '../modules/page-wrapper'
import { RootPage as RootPageModule } from '../modules/root'
import { Page } from '../types/page.types'

const RootPage: React.FC<Page> = (props) => (
  <PageWrapper {...props}>
    <RootPageModule />
  </PageWrapper>
)

export default RootPage

export { getServerSideProps }
