import { useRouter } from 'next/router'
import React, { useContext, useEffect } from 'react'
import { Context } from '../../context'

export const RootPage: React.FC = () => {
  const router = useRouter()
  const { userToken } = useContext(Context)

  useEffect(() => {
    if (userToken) {
      router.push('/library')
    }
  }, [router, userToken])

  return null
}
