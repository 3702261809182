import { Context as ContextAppFrame, Icon } from 'scala'
import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context'

export const useToastSlowerProcessing = (): void => {
  const [STORAGE_NAME] = useState('moises:banner-slower-processing')
  const { pathname, push } = useRouter()
  const { user } = useContext(ContextAppFrame)
  const {
    toast: { add: addToast }
  } = useContext(Context)

  const fireToast = useCallback(() => {
    addToast({
      fixed: true,
      closable: true,
      type: 'default',
      icon: <Icon name="alert" />,
      buttonText: 'Upgrade Now',
      onConfirm: () => push('/pricing/'),
      title: 'Demand is off the charts!',
      description:
        'Our processing time is longer than usual. Premium users have priority.'
    })
    // we do not want to track fireToast as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      !sessionStorage.getItem(STORAGE_NAME) &&
      user?.featureFlags?.slowerProcessingTimeOnWeb &&
      !user?.subscription.isPremium &&
      pathname !== '/not-found'
    ) {
      fireToast()
      sessionStorage.setItem(STORAGE_NAME, 'true')
    }
  }, [user, pathname, STORAGE_NAME, fireToast])
}
